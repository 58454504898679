body {
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.container {
  // background: white;
  // padding: 30px;
  // border-radius: 10px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  // margin-top: 30px;
  
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border: 10px solid #000; /* Black border */
  border-radius: 10px; /* Rounded corners */
  background: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}


h2, h4 {
  color: #343a40;
}
h2{
  font-size: 30px!important;
}
h4 {
  font-size: 20px!important;
}

.table {
  margin-top: 20px;
}

ul {
  padding-left: 20px;
}

.signature {
  margin-top: 30px;
  font-weight: bold;
}

.confirm-section {
  margin-top: 20px;
}

.btn-container {
  margin-top: 30px;
}

.btn {
  margin-right: 10px;
}

.popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 300px;
}
.popup input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.popup button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}
.popup button:hover {
    background-color: #0056b3;
}

.btn-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  background-size: 200%;
  color: #ffffff;
  transition: background-position 0.3s ease, box-shadow 0.3s ease;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.25);
  }
}

.btn-primary {
  background-image: linear-gradient(45deg, #007bff, #0056b3);
  
  &:hover {
    background-position: right center;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.4);
  }
}

.btn-success {
  background-image: linear-gradient(45deg, #28a745, #1e7e34);
  
  &:hover {
    background-position: right center;
    box-shadow: 0 4px 10px rgba(40, 167, 69, 0.4);
  }
}

.otp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.otp-modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  
  h3 {
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1.5rem;
  }
}

.otp-form {
  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    
    .otp-digit {
      width: 50px;
      height: 50px;
      font-size: 1.5rem;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
  
  .otp-actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
}

/* Button secondary style for the Close button */
.btn-secondary {
  background-image: linear-gradient(45deg, #6c757d, #5a6268);
  &:hover {
    background-position: right center;
    box-shadow: 0 4px 10px rgba(108, 117, 125, 0.4);
  }
}